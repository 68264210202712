import React from "react"
import Layout from "../components/Layout"

const AboutPage = () => (
  <>
    <Layout>
      <h1>What is The Polymathman?</h1>
      <p>
        The Polymathman is a Journal that documents many advanced topics in tech, music, woodworking
        and other projects. Originally, a concept of DevOps Architect {" "}
        <a href="https://github.com/timmy3421" target="_blank" rel="noreferrer">
          Tim Mechaley
        </a>
        , Tim uses this Journal to bring other like minded people, (as future contributors), to showcase
        better ways to do things in the tech field and the other fields mentioned in the Journal. The Journal
        is not limited to just DevOps. Hence, the use of the word "Polymath" implied in the name. 
      </p>
      <h3>About the Journal's Site</h3>
      <p>
        The best part about the Journal is that it is used as a test bed for a few key tech concepts
        in it's CI/CD pipeline. The very first two Journal posts are about how the Journal operates using two ideals:
        XAC and GitOps. The Journal is fully automated in it's delivery. The entire Journal, including it's QA site and future
        test bed will be public code.
      </p>
      <h2>Can you code it tho?</h2>
      <p>
        The Journal is not a "Hello World" Journal. Rather, it is a "Real World" Journal. The Journal contributors assume the readers have done their due diligence 
        and already know what they are doing in the field covered. The Journal's purpose is to provide insight and documentation
        on concepts that have not been documented in some of the advanced concepts covered here. 
      </p>
      <p>
        "Can you code it tho?" comes from the frustration of seeing architectural diagrams thrown around while the creator is not able to code the implementation of said diagram. 
      </p>
      <p>
        The Journal, when it references tech, prefers code over button-clicking/template DevOps admins. Hence, "Can you code it tho?"
      </p>
      <p>
      <h4>
        Start reading about how this site is created/deployed/automated {" "}
        <a href="/about-this-site">here.</a>
      </h4>
      </p>
    </Layout>
  </>
)

export default AboutPage
